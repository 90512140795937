// if you need to add custom js put it in here
$(window).load(function () {
    $('.HButton').bootstrapSwitch();
    $('.about-content-wrapper').matchHeight();
    $('.blogTitle').matchHeight();
    $('.pf-recent-related-product-item.product-match-height').matchHeight();
    $('.div-block-6 .textHeight').matchHeight();
    $('.call-text').matchHeight();
    $('.flex-text').matchHeight();
    $('.customer-info-wrapper .textHeight').matchHeight();
    $('.pf-product-desktop-slider-arrow.w-slider-arrow-left').click(function () {
        var previousThumb = $('.pf-product-image-thumb.w--current').prev();
        if (previousThumb.length > 0) {
            previousThumb.click();
        } else{
            $('.pf-product-image-thumb').last().click();
        }
    });
    $('.pf-product-desktop-slider-arrow.w-slider-arrow-right').click(function () {
        var nextThumb = $('.pf-product-image-thumb.w--current').next();
        if (nextThumb.length > 0) {
            nextThumb.click();
        } else {
            $('.pf-product-image-thumb').first().click();
        }
    });
});
$(document).on("scroll", function () {
    if ($(document).scrollTop() > 100) {
        $(".top-right .nav-link").addClass("link-shrink");
        $(".w-nav-menu .nav-link").addClass("nav-no-padding");
        $(".logo").addClass("logo-small");
        $(".searchform-wrapper").addClass("search-shrink");
        $(".image-24").addClass("logo-small");
    }
    else {
        $(".top-right .nav-link").removeClass("link-shrink");
        $(".w-nav-menu .nav-link").removeClass("nav-no-padding");
        $(".logo").removeClass("logo-small");
        $(".searchform-wrapper").removeClass("search-shrink");
        $(".image-24").removeClass("logo-small");
    }
});
$(function () {
    $(window).on('resize', function () {
        SetNavDropdownMaxHeight();
        SetMegaNavDropdownPosition();
    });
    $(document).on('click', '.menu-button', function () {
        SetNavDropdownMaxHeight();
    });
    $(document).on('click', '.nav-link.nav-dropdown', function () {
        SetNavDropdownMaxHeight();
        SetMegaNavDropdownPosition();
    });
    //$(document).on('click', '.bag-opener.w-nav-link', function () {
    //    SetNavDropdownMaxHeight();
    //});
    //$('#bag-opener').click(function () {
    //    SetNavDropdownMaxHeight();
    //});
});

function SetNavDropdownMaxHeight() {
    if ($(window).width() < 768) {
        $('.w-nav-overlay').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.topheader').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('nav.w-nav-menu .mega-list').css('max-height', '100%');
    } else {
        $('nav.w-nav-menu .mega-list').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.topheader').css('max-height', '400px');
        $('.topheader').css('overflow', 'auto');
        $('.w-nav-overlay').css('max-height', '100%');
    }
}

function SetMegaNavDropdownPosition() {
    if ($(window).width() >= 768) {
        $('nav.w-nav-menu .mega-list').each(function () {
            var $navMenu = $(this).closest('nav.nav-menu');
            var $dropdown = $(this).closest('.w-dropdown');
            $(this).css('left', ($navMenu.offset().left - $dropdown.offset().left) + 'px');
            if ($(window).width() < 992)
                $(this).css('right', (($dropdown.offset().left + $dropdown.width()) - $(window).width()) + 'px');
            else
                $(this).css('right', 'auto');
        });
    }
}
